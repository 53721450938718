import { dsvFormat, timeParse } from 'd3';
import { Regions, Catastrophes, DefaultEventsData } from './constants';
import nanoid from 'nanoid';

const parseCsvText = dsvFormat(',').parse;
const parseTime = timeParse('%d/%m/%Y');

const toBool = val => val === '1';
const MILLION = 1000000;

export default function parseCsv(text) {
  return parseCsvText(text, d => {
    const size = d['Size$Millions'];
    const coupon = d.Coupon;
    const row = mixInDefaultData({
      id: nanoid(),
      date: parseTime(d.DateOfIssue),
      deal: d.Deal,
      regions: [
        toBool(d.IncludesNorthAmerica) ? Regions.NORTH_AMERICA : null,
        toBool(d.IncludesEurope) ? Regions.EUROPE : null,
        toBool(d.IncludesJapan) ? Regions.JAPAN : null,
        toBool(d.IncludesOtherRegions) ? Regions.OTHER : null
      ].filter(Boolean),
      catastrophes: [
        toBool(d.IncludesHurricane) ? Catastrophes.HURRICANE : null,
        toBool(d.IncludesEarthquake) ? Catastrophes.EARTHQUAKE : null,
        toBool(d.IncludesWinterStorm) ? Catastrophes.WINTER_STORM : null
      ].filter(Boolean),
      dollarSize: size.length > 0 ? parseFloat(size) * MILLION : null,
      couponSize: coupon.length > 0 ? parseFloat(coupon) / 100 : null,
      riskModeller: d.RiskModeller,
      bigThreeModeller: d.Big3Modeller,
      defaulted: toBool(d.Defaulted)
    });

    return row;
  });
}

const defaultData = {
  'George Town Re Ltd': {
    defaultEvent: 'lothar',
    defaultPercentage: 0.03,
    defaultYear: 1999
  },
  'Avalon Re Ltd Class C': {
    defaultEvent: 'katrina',
    defaultPercentage: 0.1,
    defaultYear: 2005
  },
  'Kamp Re 2005 Ltd': {
    defaultEvent: 'katrina',
    defaultPercentage: 0.75,
    defaultYear: 2005,
  },
  'Ajax Re Ltd': {
    defaultEvent: 'lehmann',
    defaultPercentage: 0.75,
    defaultYear: 2008
  },
  'Willow Re Ltd 2008-1': {
    defaultEvent: 'lehmann',
    defaultPercentage: 0.125,
    defaultYear: 2008
  },
  'Newton Re Ltd (2008-1)': {
    defaultEvent: 'lehmann',
    defaultPercentage: 0.07,
    defaultYear: 2008
  },
  'Carillon Ltd Class A-1': {
    defaultEvent: 'lehmann',
    defaultPercentage: 0.625,
    defaultYear: 2008
  },
  'Muteki Ltd': {
    defaultEvent: 'tohoku',
    defaultPercentage: 1.0,
    defaultYear: 2011
  },
  'Mariah Re Ltd 2010-1': {
    defaultEvent: 'thunderstorm',
    defaultPercentage: 1.0,
    defaultYear: 2011
  },
  'Mariah Re Ltd 2010-2': {
    defaultEvent: 'thunderstorm',
    defaultPercentage: 1.0,
    defaultYear: 2011
  }
};

function mixInDefaultData(row) {
  if (!row.defaulted) {
    return row;
  }

  const event = defaultData[row.deal];
  if (!event) {
    console.warn('Could not find event for', row.deal);
    return row;
  }

  return { ...row, ...event };
}
