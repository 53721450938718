import { h } from 'preact';
import cx from 'classnames';
import './radio-button.css';

export default function RadioButton(props) {
  const { label, value, onChange, current, disabled, ...rest } = props;

  const isChecked = current === value;

  return (
    <label
      className={cx('RadioButton', {
        'RadioButton--disabled': disabled,
        'RadioButton--selected': isChecked
      })}
    >
      <input
        {...rest}
        type="radio"
        onChange={onChange}
        checked={isChecked}
        value={value}
        disabled={disabled}
      />{' '}
      {label}
    </label>
  );
}
