import animate from 'animateplus';
import { precisionPrefix, formatPrefix, format, timeFormat } from 'd3';

export const formatDate = timeFormat('%B %_d, %Y');
export const formatFloat = format('.1f');
export const formatPercentage = format('.0%');
export const formatPercentageFloat = format('.1%');
export const formatMillions = formatPrefix(
  ',.' + precisionPrefix(1e5, 1.3e6),
  1.3e6
);
export const formatBillions = formatPrefix(
  ',.' + precisionPrefix(1e6, 1.3e7),
  1.3e7
);

export const formatFinance = d => '$' + format('0.2s')(d).replace(/G/, 'B');

export const sortDefaultedToEnd = (a, b) => {
  if (b.defaulted && !a.defaulted) {
    return -1;
  }

  if (a.defaulted && !b.defaulted) {
    return 1;
  }

  return 0;
};

const scrollRoot = document.scrollingElement;
export function smoothScrollTo(el, options) {
  const padding = 40;
  const from = scrollRoot.scrollTop;
  const top = el.getBoundingClientRect().top;
  const to = top - padding;

  return animate({
    easing: 'out-exponential',
    change: progress => {
      scrollRoot.scrollTop = from + progress * to;
    },
    duration: 300
  });
}
