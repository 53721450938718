import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import ChartByDefaults from './chart-defaults';
import ChartByCount from './chart-kinds';
import { smoothScrollTo } from '../utils';
import './article.css';

const ArticleDetails = {
  articleLink: 'https://journals.aom.org/doi/10.5465/amd.2018.0137',
  title: 'Employing Finance in Pursuit of Sustainable Development Goals',
  subtitle: 'The Promise and Perils of Catastrophe Bonds',
  datePublished: '18 September 2019',
  doi: 'DOI 10.5465/amd.2018.0137',
  doiLink: 'https://doi.org/10.5465/amd.2018.0137'
};

const getMobile = () => window.innerWidth < 767;

export default function Article(props) {
  const { data } = props;
  const [isMobile, setIsMobile] = useState(getMobile());

  function handleResize() {
    setIsMobile(getMobile());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <article className="Article">
      <a
        className="Article-headerReturnLink container wide"
        href={ArticleDetails.articleLink}
      >
        Return to Academy of Management Discoveries
      </a>
      <header className="Article-header container wide">
        <h4 className="Article-notes">
          {ArticleDetails.datePublished} <span>&middot;</span>{' '}
          <a href={ArticleDetails.doiLink}>{ArticleDetails.doi}</a>
        </h4>
        <h1 className="Article-title">{ArticleDetails.title}</h1>
        <h2 className="Article-subtitle">{ArticleDetails.subtitle}</h2>
        <h3 className="Article-authors">
          By <span>Dror Etzion</span>, <span>Emmanuel Kypraios</span>, and{' '}
          <span>Bernard Forgues</span>
        </h3>
      </header>
      <div className="container">
        <p className="Article-notice">
          This web page is a supplement to the above article which can be read
          at the{' '}
          <a href={ArticleDetails.articleLink}>
            Academy of Management Discoveries
          </a>
          .
        </p>
      </div>
      <div className="Article-content container">
        <p>
          Catastrophe bonds are a financial tool for insuring against
          catastrophic natural events such as earthquakes, hurricanes, and
          winter storms. At issuance, investors purchase the bond, providing
          principal that is typically held in an escrow account. Then, investors
          receive coupon payments until the bond reaches maturity, at which
          point the principal is returned to the investor.
        </p>
        <p>
          However, if the covered catastrophe occurs during the risk period,
          part or all of the money is transferred to the issuer, with the
          purpose of covering their insurance losses. Conditions vary from bond
          to bond, but often specify a geographic region, the kind of disaster
          under which the bond will trigger, and more.
        </p>
        <p>
          All catastrophe bonds issued from January 1997 to December 2015 are
          visualized in the two figures below.
        </p>
        <BondCountSection data={data} isMobile={isMobile} />
        <BondCouponSection data={data} isMobile={isMobile} />
      </div>
      <footer className="Article-footer container">
        <p>
          The data used in this research is based on the{' '}
          <a href="https://www.artemis.bm/">Artemis Deal Directory</a>. For more
          about catastrophe bonds, read{' '}
          <a href={ArticleDetails.articleLink}>the full paper</a>.
        </p>
      </footer>
    </article>
  );
}

const highlights = {
  'financial-crisis': {
    figure: 'figure-count',
    rollup: 'count',
    group: 'region'
  },
  'financial-drop': {
    figure: 'figure-count',
    rollup: 'totalBondSize',
    group: 'region'
  },
  'winter-storms': {
    figure: 'figure-count',
    rollup: 'averageCouponSize',
    group: 'catastrophe'
  },
  defaulted: {
    figure: 'figure-coupon'
  }
};

function BondCountSection(props) {
  const { data, isMobile } = props;
  const [chartBFilterRollup, setFilterRollup] = useState('count');
  const [chartBFilterGroup, setFilterGroup] = useState('catastrophe');

  function handleHighlightClick(id) {
    const highlight = highlights[id];
    const highlightedEl = document.querySelector(`#${highlight.figure}`);

    smoothScrollTo(highlightedEl).then(() => {
      setFilterRollup(highlight.rollup);
      setFilterGroup(highlight.group);
    });
  }

  function handleRollupChange(val) {
    setFilterRollup(val);
  }

  function handleGroupChange(val) {
    setFilterGroup(val);
  }

  return (
    <Fragment>
      <h3>Bonds & the Financial Market</h3>
      <p>
        As a relatively new financial instrument, catastrophe bonds underwent a
        number of changes in the last two decades. The catastrophes covered,
        bond coupons, and total value of these bonds have evolved alongside
        disasters and financial market events.
      </p>
      <figure id="figure-count">
        {data ? (
          <ChartByCount
            data={data}
            isMobile={isMobile}
            filterGroup={chartBFilterGroup}
            filterRollup={chartBFilterRollup}
            onChangeRollup={handleRollupChange}
            onChangeGroup={handleGroupChange}
          />
        ) : (
          <div class="Loading">Loading...</div>
        )}
      </figure>
      <p>
        Most notably, in the financial bubble that led up to the 2008 crisis, a
        relatively large number of bonds were issued, with{' '}
        <a
          href="#"
          className="highlight orange"
          onClick={e => {
            e.preventDefault();
            handleHighlightClick('financial-crisis');
          }}
        >
          55 new bonds covering catastrophes in North America alone
        </a>{' '}
        during 2007. These numbers{' '}
        <a
          href="#"
          className="highlight orange"
          onClick={e => {
            e.preventDefault();
            handleHighlightClick('financial-drop');
          }}
        >
          dropped significantly
        </a>{' '}
        after the crash.
      </p>
      <p>
        Additionally,{' '}
        <a
          href="#"
          className="highlight purple"
          onClick={e => {
            e.preventDefault();
            handleHighlightClick('winter-storms');
          }}
        >
          winter storm bonds
        </a>
         only entered the market around 2007, and they have generally provided
        higher coupon rates for investors.
      </p>
      <p>
        As catastrophe bonds have become more mainstream, their spread has come
        to closely resemble that of similarly rated corporate bonds.
      </p>
    </Fragment>
  );
}

function BondCouponSection(props) {
  const { data, isMobile } = props;

  const [filterCatastrophe, setFilterCatastrophe] = useState('all');
  const [filterRegion, setFilterRegion] = useState('all');

  function handleHighlightClick(id) {
    const highlight = highlights[id];
    const highlightedEl = document.querySelector(`#${highlight.figure}`);

    smoothScrollTo(highlightedEl).then(() => {
      setFilterCatastrophe('all');
      setFilterRegion('all');
    });
  }

  return (
    <Fragment>
      <h3>Bond Size & Default Rates</h3>
      <p>
        Throughout the duration of the bond, investors recieve coupon payments.
        This coupon rate is laid out in the bond's <em>prospectus</em>, and is
        informed by catastrophe risk models. Higher coupon rates often
        compensate investors willing to take on higher risk of default.
      </p>
      <figure id="figure-coupon">
        {data ? (
          <Fragment>
            <ChartByDefaults
              data={data}
              isMobile={isMobile}
              filterRegion={filterRegion}
              filterCatastrophe={filterCatastrophe}
              onChangeRegion={setFilterRegion}
              onChangeCatastrophe={setFilterCatastrophe}
            />
          </Fragment>
        ) : (
          <div class="Loading">Loading...</div>
        )}
      </figure>
      <p>
        Of the {data ? data.length : '...'} bonds reviewed, only{' '}
        <a
          href="#"
          className="highlight red"
          onClick={e => {
            e.preventDefault();
            handleHighlightClick('defaulted');
          }}
        >
          a mere 10 defaulted
        </a>
        . While this number may seem surprising, bonds are only triggered when
        losses are large or truly catastrophic, making the number of defaults
        much smaller than the number of natural disasters in a given period.
      </p>
      <p>
        Notably, four of these defaults were caused not by a natural disaster,
        but rather the 2008 financial crisis.
      </p>
      <p>
        Six of the ten bonds defaulted on the majority of the bond value, while
        the other four returned 85% or more of the principal to investors.
      </p>
    </Fragment>
  );
}
