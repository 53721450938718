export const TimePeriod = [1997, 2015];

export const Regions = {
  ALL: 'all',
  NORTH_AMERICA: 'north_america',
  EUROPE: 'europe',
  JAPAN: 'japan',
  OTHER: 'other'
};

export const Catastrophes = {
  ALL: 'all',
  HURRICANE: 'hurricane',
  EARTHQUAKE: 'earthquake',
  WINTER_STORM: 'winter_storm',
  OTHER: 'other'
};

export const ReadableNames = {
  [Regions.ALL]: 'Any',
  [Regions.NORTH_AMERICA]: 'North America',
  [Regions.EUROPE]: 'Europe',
  [Regions.JAPAN]: 'Japan',
  [Regions.OTHER]: 'Other',
  [Catastrophes.ALL]: 'Any',
  [Catastrophes.EARTHQUAKE]: 'Earthquake',
  [Catastrophes.WINTER_STORM]: 'Winter Storm',
  [Catastrophes.HURRICANE]: 'Hurricane'
};

export const DefaultEventsData = {
  lehmann: {
    id: 'lehmann',
    date: new Date(2008, 8, 15),
    title: '2008 Financial Crisis',
    regions: [],
    catastrophe: Catastrophes.OTHER
  },
  katrina: {
    id: 'katrina',
    date: new Date(2005, 7, 28),
    title: 'Hurricane Katrina',
    regions: [Regions.NORTH_AMERICA],
    catastrophe: Catastrophes.HURRICANE
  },
  lothar: {
    id: 'lothar',
    date: new Date(1999, 11, 25),
    title: 'Windstorm Lothar',
    regions: [Regions.EUROPE],
    catastrophe: Catastrophes.HURRICANE
  },
  tohoku: {
    id: 'tohoku',
    date: new Date(2011, 2, 11),
    title: 'Tōhoku Earthquake',
    regions: [Regions.JAPAN],
    catastrophe: Catastrophes.EARTHQUAKE
  },
  thunderstorm: {
    id: 'thunderstorm',
    date: new Date(2011, 10, 1),
    title: 'Severe Thunderstorm',
    regions: [Regions.NORTH_AMERICA],
    catastrophe: Catastrophes.HURRICANE
  }
};
