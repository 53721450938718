import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import parseCsv from '../parse-csv';
import csvUrl from '../bonds.csv';
import Article from './article';

export default function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(csvUrl)
      .then(res => res.text())
      .then(res => {
        const data = parseCsv(res);
        setData(data);
      })
      .catch(err => {
        console.error(err.message);
        setError(err.message);
      });
  }, []);

  return <Article data={data} />;
}
