import { h, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import {
  axisBottom,
  axisLeft,
  scaleLinear,
  scaleBand,
  scaleTime,
  timeMonth,
  timeYear,
  extent,
  max,
  min,
  select,
  voronoi
} from 'd3';
import { Delaunay } from 'd3-delaunay';
import cx from 'classnames';
import RadioButton from './radio-button';
import { Catastrophes, Regions, DefaultEventsData } from '../constants';
import {
  formatPercentage,
  formatPercentageFloat,
  formatFinance,
  formatDate,
  sortDefaultedToEnd
} from '../utils';
import './chart-defaults.css';

const padding = {
  top: 30,
  left: 80,
  right: 20,
  bottom: 60
};
const disasters = Object.values(DefaultEventsData);

const getWidth = isMobile => (isMobile ? 440 : 728);

export default function Chart(props) {
  const {
    data,
    isMobile,
    filterCatastrophe,
    filterRegion,
    onChangeCatastrophe,
    onChangeRegion
  } = props;

  const [width, setWidth] = useState(getWidth(isMobile));
  const [height, setHeight] = useState(500);
  const [highlightedDot, setHighlighedDot] = useState(null);
  const plotAreaWidth = width - padding.right - padding.left;
  const plotAreaHeight = height - padding.top - padding.bottom;
  const delaunayRadius = plotAreaWidth / 10;
  const xMin = padding.left;
  const yMin = padding.top;

  const x = scaleTime()
    .domain(extent(data, d => timeMonth.floor(d.date)))
    .range([padding.left, width - padding.right]);
  const y = scaleLinear()
    .domain([0, max(data, d => d.couponSize)])
    .range([height - padding.bottom, padding.top])
    .nice()
    .clamp(true);
  const size = scaleLinear()
    .domain(extent(data, d => d.dollarSize))
    .range([2.5, 14]);

  const filteredData = data
    .filter(d => {
      if (!d.couponSize) {
        return false;
      }

      if (filterCatastrophe !== Catastrophes.ALL) {
        if (!d.catastrophes.includes(filterCatastrophe)) {
          return false;
        }
      }

      if (filterRegion !== Regions.ALL) {
        if (!d.regions.includes(filterRegion)) {
          return false;
        }
      }

      return true;
    })
    .sort(sortDefaultedToEnd);

  const delaunay = Delaunay.from(
    filteredData,
    d => x(timeMonth.floor(d.date)),
    d => y(d.couponSize)
  );

  function handleCatastropheChange(e) {
    onChangeCatastrophe(e.target.value);
  }

  function handleRegionChange(e) {
    onChangeRegion(e.target.value);
  }

  function onMouseMove(e) {
    const rect = e.target.getBoundingClientRect();
    const mouseX = e.clientX - rect.x;
    const mouseY = e.clientY - rect.y;
    const pointIndex = delaunay.find(
      mouseX + padding.left,
      mouseY + padding.top
    );
    setHighlighedDot(pointIndex);
  }

  function onMouseLeave() {
    setHighlighedDot(null);
  }

  useEffect(() => {
    setWidth(getWidth(isMobile));
  }, [isMobile]);

  const highlightedPoint = highlightedDot ? filteredData[highlightedDot] : null;

  return (
    <div
      className={cx('ChartA', {
        'ChartA--highlighted': Boolean(highlightedDot)
      })}
    >
      <div class="Filters">
        <div class="FilterRow">
          <strong>Catastrophe Covered</strong>
          <RadioButton
            label="All"
            value={Catastrophes.ALL}
            current={filterCatastrophe}
            onChange={handleCatastropheChange}
          />
          <RadioButton
            label="Earthquake"
            value={Catastrophes.EARTHQUAKE}
            current={filterCatastrophe}
            onChange={handleCatastropheChange}
          />
          <RadioButton
            label="Winter Storm"
            value={Catastrophes.WINTER_STORM}
            current={filterCatastrophe}
            onChange={handleCatastropheChange}
          />
          <RadioButton
            label="Hurricane"
            value={Catastrophes.HURRICANE}
            current={filterCatastrophe}
            onChange={handleCatastropheChange}
          />
        </div>
        <div class="FilterRow">
          <strong>Region Covered</strong>
          <RadioButton
            label="All"
            value={Regions.ALL}
            current={filterRegion}
            onChange={handleRegionChange}
          />
          <RadioButton
            label="North America"
            value={Regions.NORTH_AMERICA}
            current={filterRegion}
            onChange={handleRegionChange}
          />
          <RadioButton
            label="Europe"
            value={Regions.EUROPE}
            current={filterRegion}
            onChange={handleRegionChange}
          />
          <RadioButton
            label="Japan"
            value={Regions.JAPAN}
            current={filterRegion}
            onChange={handleRegionChange}
          />
          <RadioButton
            label="Other"
            value={Regions.OTHER}
            current={filterRegion}
            onChange={handleRegionChange}
          />
        </div>
        <div className="FilterRow Legend">
          <span style="display: flex; align-items: center; font-size: 0.8125rem; color: #454545;">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              style="margin-right: 0.35rem;"
            >
              <circle
                cx="9"
                cy="9"
                r="7"
                fill="none"
                stroke="#aaa"
                stroke-width="1"
              />
              <circle
                cx="9"
                cy="11"
                r="5"
                fill="none"
                stroke="#aaa"
                stroke-width="1"
              />
              <circle
                cx="9"
                cy="13"
                r="3"
                fill="none"
                stroke="#aaa"
                stroke-width="1"
              />
            </svg>
            Dot size represents bond value
          </span>
        </div>
      </div>
      {highlightedPoint && (
        <Tooltip xScale={x} yScale={y} d={highlightedPoint} />
      )}
      <svg
        className="ChartA-svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <HorizontalAxis scale={x} canvasWidth={width} canvasHeight={height} />
        <VerticalAxis scale={y} canvasWidth={width} canvasHeight={height} />
        <g transform="translate(0, 0)">
          {filteredData.map((d, i) => (
            <Dot
              d={d}
              xScale={x}
              yScale={y}
              sizeScale={size}
              canvasHeight={height}
              isHighlighted={highlightedDot === i}
            />
          ))}
        </g>
        {!isMobile && (
          <rect
            className="Overlay"
            x={xMin}
            y={yMin}
            fill="transparent"
            width={plotAreaWidth}
            height={plotAreaHeight}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseLeave}
          />
        )}
        <g transform="translate(0, 0)">
          {disasters.map((d, i) => (
            <DefaultEventMarker
              defaultEvent={d}
              bonds={filteredData}
              xScale={x}
              yScale={y}
            />
          ))}
        </g>
      </svg>
    </div>
  );
}

function Dot(props) {
  const { xScale, yScale, sizeScale, d, isHighlighted, canvasHeight } = props;

  const xPos = xScale(timeMonth.floor(d.date));
  const yPos = yScale(d.couponSize);
  const dotSize = sizeScale(d.dollarSize);
  const className = cx('Dot', { 'Dot--highlighted': isHighlighted });

  if (d.defaulted) {
    const defaultEvent = DefaultEventsData[d.defaultEvent];
    const defaultEventXPos = xScale(defaultEvent.date);

    const lineX1Pos = Math.min(defaultEventXPos, xPos);
    const lineX2Pos = defaultEventXPos;
    const lineWidth = Math.abs(defaultEventXPos - xPos);
    const defaultedDotSize = Math.max(dotSize * (1.0 - d.defaultPercentage), 1);

    return (
      <g>
        <circle
          className={className}
          data-defaulted={d.defaulted}
          cx={xPos}
          cy={yPos}
          r={dotSize}
        />
        <rect
          className="Dot-defaultLine"
          x={lineX1Pos}
          y={yPos - 0.25}
          width={lineWidth}
          height={0.5}
          fill="#ccc"
        />
        <circle
          className={className}
          data-defaulted={d.defaulted}
          cx={lineX2Pos}
          cy={yPos}
          r={defaultedDotSize}
        />
      </g>
    );
  }

  return (
    <circle
      className={className}
      data-defaulted={d.defaulted}
      cx={xPos}
      cy={yPos}
      r={dotSize}
    />
  );
}

function HorizontalAxis(props) {
  const { scale, canvasWidth, canvasHeight } = props;
  const ref = useRef(null);

  useEffect(() => {
    select(ref.current).call(axisBottom(scale));
  }, [scale]);

  return (
    <g
      className="Chart-axis Chart-xAxis"
      transform={`translate(0, ${canvasHeight - padding.bottom + 10})`}
      ref={ref}
    >
      <text className="Chart-axisLabel" x={canvasWidth / 1.95} y={50}>
        Year
      </text>
    </g>
  );
}

function VerticalAxis(props) {
  const { scale, canvasWidth, canvasHeight } = props;
  const ref = useRef(null);

  useEffect(() => {
    const axis = axisLeft(scale)
      .tickSize(-canvasWidth)
      .tickFormat(d => formatPercentage(d));
    select(ref.current).call(axis);
  }, [scale]);

  return (
    <g
      className="Chart-axis Chart-yAxis"
      transform={`translate(${padding.left - 25}, 0)`}
      ref={ref}
    >
      <text
        className="Chart-axisLabel"
        x={-canvasHeight / 2}
        y={-45}
        transform="rotate(-90)"
      >
        Coupon Rate
      </text>
    </g>
  );
}

function DefaultEventMarker(props) {
  const { bonds, defaultEvent, xScale, yScale } = props;

  const bondsWithThisDisaster = bonds.filter(b => {
    return b.defaultEvent === defaultEvent.id;
  });

  const xPos = xScale(defaultEvent.date);
  const yPositions = bondsWithThisDisaster.map(d => yScale(d.couponSize));

  if (yPositions.length < 1) {
    return null;
  }

  const lowerY = max(yPositions);
  const upperY = min(yPositions) - 60;

  return (
    <g
      className="DefaultEventMarker"
      transform={`translate(${xPos}, ${upperY})`}
    >
      <rect
        className="DefaultEventMarker-line"
        x="0"
        y="0"
        width="0.25"
        height={lowerY - upperY}
        fill="#141414"
      />
      <text className="DefaultEventMarker-label annotation" y="-10" dy="0.25em">
        {defaultEvent.title}
      </text>
    </g>
  );
}

function Tooltip(props) {
  const { d, xScale, yScale } = props;

  const xPos = xScale(timeMonth.floor(d.date));
  const yPos = yScale(d.couponSize);
  const positionTransform = `translate(${xPos}px, ${yPos}px)`;
  const alignmentTransform = `translate(-50%, -100%) translateY(-1rem)`;

  return (
    <div
      className="Tooltip"
      style={{
        transform: `${positionTransform} ${alignmentTransform}`
      }}
    >
      {d.defaulted && <div className="Tooltip-badge">Defaulted</div>}
      <h4 className="Tooltip-title">{d.deal}</h4>
      <ul>
        <li>
          <span>Issued</span> {formatDate(timeMonth.floor(d.date))}
        </li>
        {d.defaulted && (
          <li>
            <span>Defaulted</span> {d.defaultYear}
          </li>
        )}
        <li>
          <span>Bond Value</span> {formatFinance(d.dollarSize)}
        </li>
        {d.defaulted && (
          <Fragment>
            <li>
              <span>Default Amount</span>{' '}
              {formatPercentage(d.defaultPercentage)} of bond
            </li>
            <li>
              <span>Default Event</span>{' '}
              {DefaultEventsData[d.defaultEvent].title}
            </li>
          </Fragment>
        )}
        <li>
          <span>Coupon</span> {formatPercentageFloat(d.couponSize)}
        </li>
      </ul>
    </div>
  );
}
