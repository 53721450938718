import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import {
  ascending,
  descending,
  axisBottom,
  axisLeft,
  scaleLinear,
  scaleBand,
  scaleTime,
  scaleOrdinal,
  timeMonth,
  timeQuarter,
  timeYear,
  timeFormat,
  format,
  line as drawLine,
  curveCardinal,
  extent,
  nest,
  max,
  min,
  sum,
  select
} from 'd3';
import cx from 'classnames';
import RadioButton from './radio-button';
import { Catastrophes, Regions, ReadableNames } from '../constants';
import {
  formatFinance,
  formatPercentage,
  formatPercentageFloat
} from '../utils';
import './chart-kinds.css';

const padding = {
  top: 40,
  left: 55,
  right: 15,
  bottom: 40
};

const regionKeys = [
  Regions.NORTH_AMERICA,
  Regions.EUROPE,
  Regions.JAPAN,
  Regions.OTHER
];
const catastropheKeys = [
  Catastrophes.EARTHQUAKE,
  Catastrophes.HURRICANE,
  Catastrophes.WINTER_STORM
];

const colorScales = {
  none: ['#4e5469'],
  region: ['#ff8c00', '#5f3f94', '#c74e3e', '#98abc5'],
  catastrophe: ['#7e237b', '#6d79bd', 'rgba(155, 165, 219, 0.75)']
};

const annotations = [
  {
    id: '1',
    startPoint: { date: 2003.25, value: 57.5 },
    mobileStartPoint: { date: 2006, value: 54 },
    textPoint: { date: 1998, value: 62 },
    endPoint: { date: 2006.5, value: 51 },
    rollup: 'count',
    group: ['catastrophe'],
    title: 'Peak year',
    text: [
      'In the financial bubble that',
      'led up to the 2008 financial crisis,',
      'the most bonds were issued.'
    ]
  },
  {
    id: '2',
    startPoint: { date: 2003.15, value: 7400000000 },
    mobileStartPoint: { date: 2005.25, value: 6800000000 },
    textPoint: { date: 1998, value: 8000000000 },
    endPoint: { date: 2006.75, value: 6050000000 },
    rollup: 'totalBondSize',
    group: ['catastrophe'],
    title: 'Peak year',
    text: [
      'The financial bubble also led to',
      'the second highest year of total',
      'bond value.'
    ]
  },
  {
    id: '3',
    startPoint: { date: 2002.85, value: 0.149 },
    mobileStartPoint: { date: 2005.75, value: 0.1435 },
    textPoint: { date: 1998, value: 0.16 },
    endPoint: { date: 2008.55, value: 0.1415 },
    rollup: 'averageCouponSize',
    group: ['catastrophe'],
    title: 'Winter storm bonds',
    text: [
      'Only entering the market in',
      '2007 winter storm bonds have',
      'generally provided higher',
      'coupon rates.'
    ]
  },
  {
    id: '4',
    startPoint: { date: 2003.25, value: 57.5 },
    mobileStartPoint: { date: 2006.25, value: 55.6 },
    textPoint: { date: 1998, value: 62 },
    endPoint: { date: 2006.65, value: 55.5 },
    rollup: 'count',
    group: ['region'],
    title: 'Peak year',
    text: [
      'In the financial bubble that',
      'led up to the 2008 financial crisis,',
      'the most bonds were issued.'
    ]
  },
  {
    id: '5',
    startPoint: { date: 2007.85, value: 6650000000 },
    mobileStartPoint: { date: 2007.85, value: 6500000000 },
    textPoint: { date: 2005.75, value: 8000000000 },
    endPoint: { date: 2008.25, value: 2500000000 },
    rollup: 'totalBondSize',
    group: ['region'],
    title: 'Financial crisis',
    text: [
      'Following the 2008 financial',
      'crisis, catastrophe bonds faced',
      'a significant drop.'
    ]
  }
];

const rollupFunctions = {
  count: l => {
    const none = getNoneFunction(rollupByCount)(l);
    const region = getRegionalFunction(rollupByCount)(l);
    const catastrophe = getCatastropheFunction(rollupByCount)(l);

    return {
      none,
      region,
      catastrophe
    };
  },
  averageCouponSize: l => {
    const none = getNoneFunction(rollupByAverageCoupon)(l);
    const region = getRegionalFunction(rollupByAverageCoupon)(l);
    const catastrophe = getCatastropheFunction(rollupByAverageCoupon)(l);

    return {
      none,
      region,
      catastrophe
    };
  },
  totalBondSize: l => {
    const none = getNoneFunction(rollupByTotalSize)(l);
    const region = getRegionalFunction(rollupByTotalSize)(l);
    const catastrophe = getCatastropheFunction(rollupByTotalSize)(l);

    return {
      none,
      region,
      catastrophe
    };
  }
};

const formatFunctions = {
  count: d => d.toString(),
  averageCouponSize: formatPercentageFloat,
  totalBondSize: formatFinance
};

const domainValues = {
  count: [0, 70],
  averageCouponSize: [0, 0.18],
  totalBondSize: [0, 9000000000]
};

const getWidth = isMobile => (isMobile ? 480 : 728);
const getHeight = isMobile => (isMobile ? 450 : 600);

export default function ChartByCount(props) {
  const {
    data,
    isMobile,
    filterRollup,
    filterGroup,
    onChangeGroup,
    onChangeRollup
  } = props;

  const y = scaleLinear();
  const x = scaleLinear();
  const color = scaleOrdinal();
  const line = drawLine()
    .x(d => x(d.date))
    .y(d => y(d.value))
    .curve(curveCardinal.tension(0.75));
  const [width, setWidth] = useState(getWidth(isMobile));
  const [height, setHeight] = useState(getHeight(isMobile));

  // We filter the data to be capped by date, because data in 2016 was only
  // captured for the first quarter, which messes with our aggregates.
  const dataCappedByDate = data.filter(d => d.date.getFullYear() < 2016);
  const nestFunction = nest()
    .key(getYear)
    .sortKeys(ascending)
    .rollup(rollupFunctions[filterRollup]);

  const filteredDataA =
    filterGroup === 'region'
      ? nestFunction.entries(dataCappedByDate.filter(d => d.regions.length > 0))
      : nestFunction.entries(dataCappedByDate);

  const filteredDataB = { none: [], region: [], catastrophe: [] };

  filteredDataA.forEach(v => {
    const date = Number(v.key);
    v.value.none.forEach(vv => {
      filteredDataB.none.push({
        date,
        line: vv.key,
        value: vv.value
      });
    });

    v.value.catastrophe.forEach(vv => {
      filteredDataB.catastrophe.push({
        date,
        line: vv.key,
        value: vv.value
      });
    });

    v.value.region.forEach(vv => {
      filteredDataB.region.push({
        date,
        line: vv.key,
        value: vv.value
      });
    });
  });

  const filteredData = filteredDataB;
  const filteredDataGrouped = nest()
    .key(d => d.line)
    .entries(filteredData[filterGroup]);
  const yearsOrQuarters = filteredData[filterGroup].map(l => l.date);
  const values = filteredData[filterGroup].map(l => l.value);
  const visibleAnnotations = annotations.filter(
    d => d.rollup === filterRollup && d.group.includes(filterGroup)
  );

  x.domain(extent(yearsOrQuarters)).range([
    padding.left,
    width - padding.right
  ]);
  y.domain(domainValues[filterRollup])
    .range([height - padding.bottom, padding.top])
    .nice();
  color.range(colorScales[filterGroup]);

  const formatFn = formatFunctions[filterRollup];

  function handleRollupChange(e) {
    onChangeRollup(e.target.value);
  }

  function handleGroupChange(e) {
    onChangeGroup(e.target.value);
  }

  useEffect(() => {
    setWidth(getWidth(isMobile));
    setHeight(getHeight(isMobile));
  }, [isMobile]);

  return (
    <div className="ChartB">
      <div class="Filters">
        <div class="FilterRow">
          <strong>Chart</strong>
          <RadioButton
            label="Bonds Issued"
            value="count"
            current={filterRollup}
            onChange={handleRollupChange}
          />
          <RadioButton
            label="Average Coupon Rate (%)"
            value="averageCouponSize"
            current={filterRollup}
            onChange={handleRollupChange}
          />
          <RadioButton
            label="Total Size ($B)"
            value="totalBondSize"
            current={filterRollup}
            onChange={handleRollupChange}
          />
        </div>
        <div class="FilterRow">
          <strong>Group By</strong>
          <RadioButton
            label="Region"
            value="region"
            current={filterGroup}
            onChange={handleGroupChange}
          />
          <RadioButton
            label="Catastrophe"
            value="catastrophe"
            current={filterGroup}
            onChange={handleGroupChange}
          />
        </div>
        <div className="FilterRow Legend">
          {filterGroup !== 'none' ? (
            colorScales[filterGroup].map((color, i) => (
              <label className="LegendItem">
                <div
                  className="LegendDot"
                  style={`background-color: ${color};`}
                ></div>
                {
                  ReadableNames[
                    filterGroup === 'catastrophe'
                      ? catastropheKeys[i]
                      : regionKeys[i]
                  ]
                }
              </label>
            ))
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <VerticalAxis scale={y} formatTicks={formatFn} canvasWidth={width} />
        <HorizontalAxis scale={x} y={y(0)} tickCount={isMobile ? 12 : 18} />
        {filteredDataGrouped.map(l => (
          <path
            className="Line"
            fill="none"
            stroke={color(l.key)}
            stroke-width="1.5px"
            d={line(l.values)}
          />
        ))}
        {visibleAnnotations.map(d => {
          const startPoint = isMobile
            ? d.mobileStartPoint || d.startPoint
            : d.startPoint;
          const linePath = line([startPoint, d.endPoint]);
          const textPoint = d.textPoint || startPoint;
          const textXPos = x(textPoint.date);
          const textYPos = y(textPoint.value);

          return (
            <g transform={`translate(0, 0)`}>
              <path
                fill="none"
                stroke="#141414"
                stroke-width="0.25px"
                d={linePath}
              />
              <g
                className="EventLabel"
                transform={`translate(${textXPos}, ${textYPos})`}
              >
                <text
                  className="EventLabel-title annotation"
                  x="8"
                  y="0"
                  dy="0.25em"
                >
                  {d.title}
                </text>
                <text className="EventLabel-text annotation" x="8" y="8">
                  {d.text.map(t => (
                    <tspan x="8" dy="1.25em">
                      {t}
                    </tspan>
                  ))}
                </text>
              </g>
            </g>
          );
        })}
        {filteredDataGrouped.map(l => {
          const colorValue = color(l.key);
          const sortedValues = l.values
            .slice()
            .sort((a, b) => descending(a.value, b.value));
          const apex = sortedValues[0];
          const xApex = x(apex.date);
          const yApex = y(apex.value) - 8;

          return (
            <g transform={`translate(${xApex}, ${yApex})`}>
              <text
                key={l.key}
                className="Line-label annotation"
                fill={colorValue}
                x="0"
                y="0"
              >
                {formatFn(apex.value)}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  );
}

function VerticalAxis(props) {
  const { scale, x, formatTicks, canvasWidth, ...rest } = props;
  const ref = useRef(null);

  useEffect(() => {
    const axis = axisLeft(scale)
      .tickSize(-canvasWidth)
      .tickFormat(formatTicks);
    select(ref.current).call(axis);
  }, [scale]);

  return (
    <g
      className="Chart-axis Chart-yAxis"
      transform={`translate(${padding.left - 20}, 0)`}
      ref={ref}
      {...rest}
    ></g>
  );
}

function HorizontalAxis(props) {
  const { scale, y, tickCount, ...rest } = props;
  const ref = useRef(null);

  useEffect(() => {
    const axis = axisBottom(scale)
      .ticks(tickCount)
      .tickFormat(d => d.toString());
    select(ref.current).call(axis);
  }, [scale]);

  return (
    <g
      className="Chart-axis Chart-xAxis"
      transform={`translate(0, ${y + 10})`}
      ref={ref}
      {...rest}
    ></g>
  );
}

function getYear(d) {
  return d.date.getFullYear();
}

function getCouponSize(b) {
  if (b.couponSize === null) {
    return 0;
  }

  return b.couponSize;
}

function getDollarSize(b) {
  return b.dollarSize || 0;
}

function rollupByCount(l) {
  return l.length;
}

function rollupByAverageCoupon(l) {
  if (l.length === 0) {
    return 0;
  }
  return l.reduce((a, b) => a + getCouponSize(b), 0) / l.length;
}

function rollupByTotalSize(l) {
  return l.reduce((a, b) => a + getDollarSize(b), 0);
}

function getNoneFunction(fn) {
  return l => [{ key: 'a', value: fn(l) }];
}

function getRegionalFunction(fn) {
  return l => {
    return regionKeys.map(key => {
      const filteredData = l.filter(d => d.regions.includes(key));
      const result = {
        key,
        value: fn(filteredData)
      };
      return result;
    });
  };
}

function getCatastropheFunction(fn) {
  return l => {
    return catastropheKeys.map(key => {
      const filteredData = l.filter(d => d.catastrophes.includes(key));
      const result = {
        key,
        value: fn(filteredData)
      };
      return result;
    });
  };
}
